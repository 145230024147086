export default class Navigation {
    timeline: HTMLElement
    navButtonLeft: HTMLElement
    navButtonRight: HTMLElement
    maxScrollLeft: number

    constructor() {
        this.timeline = document.getElementById('timeline') as HTMLElement
        this.navButtonLeft = document.getElementById('nav-left') as HTMLElement
        this.navButtonRight = document.getElementById('nav-right') as HTMLElement
        this.maxScrollLeft = this.timeline.scrollWidth - this.timeline.clientWidth

        this.timeline.addEventListener('wheel', (e) => {
            if (e.deltaY) {
                e.preventDefault()
                this.timeline.scrollLeft += e.deltaY
            } 

            // Check if touch pad is used. May control intertia.
            /* let isTouchPad = e.deltaY ? e.deltaY === -3 * e.deltaY : e.deltaMode === 0
            document.body.textContent = isTouchPad ? "isTouchPad" : "isMouse" */

            // Test getting scroll positon. Potentially used for a timeline miniview navigator.
            // console.log(timeline.scrollLeft)
        })

        this.navButtonRight.addEventListener('mousedown', (e) => {
            e.preventDefault()
            this.moveTimelineLeft(true)
        })

        this.navButtonLeft.addEventListener('mousedown', (e) => {
            e.preventDefault()
            this.moveTimelineLeft(false)
        })

        window.addEventListener('keydown', (e) => {
            if (e.defaultPrevented) return

            if (e.key === 'ArrowRight') {
                this.moveTimelineLeft(true)
            } else if (e.key === 'ArrowLeft') {
                this.moveTimelineLeft(false)
            }
        })
    }

    moveTimelineLeft(moveLeft: boolean): void {
        if (moveLeft) {
            if (this.timeline.scrollLeft < this.maxScrollLeft) {
                this.timeline.scrollBy({
                    left: 200,
                    behavior: 'smooth'
                })
            }
        }
        else {
            if (this.timeline.scrollLeft >= 0) {
                this.timeline.scrollBy({
                    left: -200,
                    behavior: 'smooth'
                })
            }
        }
    }
}