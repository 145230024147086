export type CarouselImage = {
    file: string, // Only define subfolders (inside .assets/photos/*) and filename with type ending.
    caption?: string
}
export type Video = {
    videoURL: string, // YouTube embed link.
    caption?: string
}
export type MediaContent = { images?: CarouselImage[], video?: Video }
export type TextContent = { title?: string, preface?: string, bodyText?: string, credit?: string }

export type Content = {
    mediaContent?: MediaContent,
    textContent?: TextContent,
}

export function getContent(id: string): Content {
    let content: Content = {
        mediaContent: media[id],
        textContent: text[id],
    }

    return content
}

const media: Record<string, MediaContent> = {
    '01': {
        images: [
            {
                file: '1_figur 1.jpg',
                caption: 'Gullmasken av farao Tutankhamon er over 3000 år gammel'
            }
        ]
    },
    '02': {
        images: [
            {
                file: '2_figur 1.jpg',
                caption: 'Sverd fra bronsealderen i Norge'
            }
        ]
    },
    '03': {
        images: [
            {
                file: '3_figur 1.jpg',
                caption: 'Pilspiss fra eldre jernalder i Norge.'
            }
        ]
    },
    '04': {
        images: [
            {
                file: '4_figur 1.jpg',
                caption: 'Filosofen Empedokles kom på teorien om de fire grunnstoffene jord, luft, ild og vann.',
            },
            {
                file: '4_figur 2.jpg',
                caption: 'Byste av filosofen Aristoteles, som la til fire egenskaper til teorien om de fire grunnstoffene.',
            },
            {
                file: '4_figur 3.jpg',
                caption: 'De fire elementene eller urgrunnstoffene; jord, luft, ild og vann.',
            }
        ]
    },
    '05': {
        images: [
            {
                file: '5_figur 1.jpg',
                caption: 'Muhammad ibn Zakariya Al-Razi i sitt laboratorium.'
            }
        ]
    },
    '06': {
        images: [
            {
                file: '6_figur 1.jpg',
                caption: 'Bildet viser gruvearbeidere i en gruve i Sulitjelma.'
            }
        ]
    },
    '07': {
        images: [
            {
                file: '7_figur 1.jpg',
                caption: 'Det berømte bildet “Alkymisten” av maleren Joseph Wright of Derby’s viser Hennig Brand som oppdager fosfor.'
            }
        ],
        video: {
            videoURL: 'https://www.youtube.com/embed/ZTb640Rbq74'
        }
    },
    '08': {
        images: [
            {
                file: '8_figur 1.jpg',
                caption: 'Forsiden av Wallichs tredje bok om eksperimenter med koboltmineralene. Boken er skrevet i 1705-06.'
            }
        ]
    },
    '09': {
        images: [
            {
                file: '9_figur 1.jpg',
                caption: 'Koboltgruvene i Modum.'
            },
            {
                file: '9_figur 2.jpg',
                caption: 'Koboltvase.'
            }
        ]
    },
    '10': {
        images: [
            {
                file: '10_figur 1.jpg',
                caption: 'Portrett av J. Jacob Berzelius.'
            },
            {
                file: '10_figur 2.jpg',
                caption: 'Berzelius’ blåserør. Gjenstand fra Kungliga Vetenskapsakademien (KVA) i Stockholm, utstilt i 2013.'
            },
            {
                file: '10_figur 3.jpg',
                caption: 'Ovn til å varme opp stoffer og digler til å blande stoffer fra Berzelius’ lab. Gjenstander fra KVA i Stockholm, utstilt i 2013.'
            }
        ]
    },
    '11': {
        images: [
            {
                file: '11_figur 1.jpg',
                caption: 'Gallium er spesielt fordi det smelter i hånda di. Smeltepunktet er bare 29,6 grader.'
            }
        ]
    },
    '12': {
        images: [
            {
                file: '12_figur 1.jpg',
                caption: 'Utstyr for kjemisk analyse.'
            },
            {
                file: '12_figur 2.jpg',
                caption: 'Forskerparet Marie og Pierre Curie i laboratoriet sammen med samarbeidspartneren M. Petit.'
            }
        ]
    },
    '13': {
        images: [
            {
                file: '13_figure 1.jpg',
                caption: 'Portrett av Robert Boyle.'
            },
            {
                file: '13_figure 2.jpg',
                caption: 'Tittelbladet til Robert Boyles bok The Sceptical Chymist (Den skeptiske kymikeren).'
            }
        ]
    },
    '14': {
        images: [
            {
                file: '14_figur 1.jpg',
                caption: 'Portrett av Emilie Du Châtelet.'
            }
        ]
    },
    '15': {
        images: [
            {
                file: '15_figur 1.jpg',
                caption: 'Forskerparet Marie-Ann Paulze og Antoine Lavoisier.'
            },
            {
                file: '15_figur 2.jpg',
                caption: 'Priestley samlet opp lufta/gassene han ville undersøke i et gassoppsamlingsapparat.'
            }
        ]
    },
    '16': {
        images: [
            {
                file: '16_figur 1.jpg',
                caption: 'Utdrag fra Lavoisiers grunnstofftabell fra 1789.'
            }
        ]
    },
    '17': {
        images: [
            {
                file: '17_figur 1.jpg',
                caption: 'Mendelejevs periodesystem fra 1869.'
            },
            {
                file: '17_figur 2.jpg',
                caption: 'Béguyer de Chancourtois’ periodesystem fra 1862.'
            }
        ]
    },
    '18': {
        images: [
            {
                file: '18_figur 1.jpg',
                caption: 'En kopi av verdens første batteri, voltasøylen.'
            },
            {
                file: '18_figur 2.jpg',
                caption: 'Voltasøylen kunne blant annet brukes sammen med et vannsplittingsapparat for å splitte vann til hydrogengass og oksygengass.'
            }
        ]
    },
    '19': {
        images: [
            {
                file: '19_figur 1.jpg',
                caption: 'Portrett av Humphrey Davy malt av Thomas Lawrence.'
            },
            {
                file: '19_figur 2.jpg',
                caption: 'Toppbildet viser Humphrey Davy som foreleser for publikum, under ser vi den enorme voltasøylen som ble brukt i forsøket.'
            }
        ],
        video: {
            videoURL: 'https://www.youtube.com/embed/nxRHQ1xfnWc'
        }
    },
    '20': {
        images: [
            {
                file: '20_figur 1.jpg',
                caption: 'Med spektroskopet fikk man en ny måte å bestemme grunnstoffer på.'
            },
            {
                file: '20_figur 2.jpg',
                caption: 'Grunnstoffet rubidium er oppkalt etter den røde flammen stoffet brenner med.'
            }
        ]
    },
    '21': {
        images: [
            {
                file: '21_figur 1.jpg',
                caption: 'Astronomen Pierre Jules Janssen var den første som oppdaget helium.'
            },
            {
                file: '21_figur 2.jpg',
                caption: 'Helium er oppkalt etter solguden Helios siden grunnstoffet ble oppdaget under en solformørkelse.'
            },
            {
                file: '21_figur 3.jpg',
                caption: 'Helium gløder med en lilla flamme'
            }
        ],
        video: {
            videoURL: 'https://www.youtube.com/embed/LowU9vKZzJs'
        }
    },
    '22': {
        images: [
            {
                file: '22_figur 1.jpg',
                caption: 'William Ramsay oppdaget edelgassene.'
            },
            {
                file: '22_figur 2.jpg',
                caption: 'Edelgassen argon ble isolert fra luft for første gang i 1894.'
            },
            {
                file: '22_figur 3.jpg',
                caption: 'Luftskip fylt med edelgassen helium.'
            }
        ]
    },
    '23': {
        images: [
            {
                file: '23_figur 1.jpg',
                caption: 'Henry Moseley.'
            },
            {
                file: '23_figur 2.jpg',
                caption: 'Forskerparet Ida og Walter Noddack.'
            },
            {
                file: '23_figur 3.jpg',
                caption: 'Ida Noddacks periodesystem fra 1934, der grunnstoff 43 angis som Ma (masurium).'
            },
            {
                file: '23_figur 4.jpg',
                caption: 'Ida Noddack i laboratoriet.'
            }
        ]
    },
    '24': {
        images: [
            {
                file: '24_figur 1.jpg',
                caption: 'Henri Becquerel.'
            },
            {
                file: '24_figur 2.jpg',
                caption: 'Fotografisk plate svertet pga. radioaktivitet.'
            },
            {
                file: '24_figur 3.jpg',
                caption: 'Marie Curie.'
            }
        ]
    },
    '25': {
        images: [
            {
                file: '25_figur 1.jpg',
                caption: 'Frederick Soddy.'
            },
            {
                file: '25_figur 2.jpg',
                caption: 'Stefanie Horovitz.'
            }
        ]
    },
    '26': {
        images: [
            {
                file: '26_figur 1.jpg',
                caption: 'Astat er verdens mest sjeldne naturlige grunnstoff.'
            },
        ]
    },
    '27': {
        images: [
            {
                file: '27_figur 1.jpg',
                caption: 'Iréne Joliot-Curie og Pierre Joliot-Curie oppdaget kunstig radioaktivitet, som senere ble brukt til å lage grunnstoffet technetium.'
            },
        ]
    },
    '28': {
        images: [
            {
                file: '28_figur 1.jpg',
                caption: 'Amerikanske Dawn Shaughnessy er en av forskerne i teamet bak oppdagelsen av grunnstoffet oganesson.'
            },
        ]
    }
}

const text: Record<string, TextContent> = {
    '01': {
        'title': `Metaller i forhistorisk tid`,
        'preface': `For mange tusen år siden lærte mennesker å bruke metallene gull, bly og kobber.`,
        'bodyText': `I dag kjenner vi til og bruker mange forskjellige stoffer hver eneste dag. Bare i mobilen vår finnes det for eksempel opptil 40 forskjellige metaller, som kobolt, wolfram, indium og gallium. Men for mange tusen år siden kjente mennesker bare til noen få metaller, og de mest brukte av dem var gull, bly og kobber.

        Gull, bly og kobber er blant de få av metallene som finnes i ren form i naturen. Disse metallene er også så myke og lette å forme at mennesker fra ulike kulturer og i ulike deler av verden lærte seg å bruke stoffene til å lage forskjellige gjenstander.
        
        Bly ble banket og formet til badekar og vannrør i Romerriket for 5000 år siden. Kobber har vært særlig populært til verktøy og våpen. I Nord-Amerika har det for eksempel blitt funnet pilspisser av kobber som ble laget av amerikanske urinnvånere for 8500 år siden.
        
        Gull, som er det eneste metallet med en vakker gul farge, har vært særlig populært i smykker. I Peru i Sør-Amerika har det blitt funnet gullperler til halskjeder som er 4000 år gamle, mens en liten smykkeperle av gull funnet i Bulgaria er over 6000 år gammel, og dette er den hittil eldste gullgjenstanden som er funnet. En av de mest kjente gjenstandene av gull er en maske som forestiller en egyptisk konge som het Tutankhamon. Gull er så varig at masken ser like vakker ut selv om det er mange tusen år siden den ble laget.`,
        'credit': `Bilde 1: MykeReeve / Wikimedia Commons, CC BY-SA 3.0`
    },
    '02': {
        'title': `Mennesker lærer å lage metallblandinger`,
        'preface': `For cirka 5000 år siden lærte mennesker å blande kobber og tinn til bronse. Med bronse fikk mennesker et hardt og holdbart materiale som kunne formes til mye bedre redskaper og våpen enn de hadde hatt tidligere.`,
        'bodyText': `Rent kobber er et mykt metall som er lett å forme. Det er dermed ikke så egnet til å lage økser av. Men smelter du kobber og blander det med smeltet tinn får du et sterkt nytt metall som kalles bronse. En slik blanding av metaller kalles legering. De første stedene der det er funnet tegn til at mennesker har laget bronse er i Armenia, som ligger i Asia.

        Det krevde mye kunnskap og utstyr for å lage bronse. For eksempel må du ha en ovn på over 1300 grader for å få smeltet kobber. Det var heller ikke helt lett å få tak i råstoff til bronse. Det finnes ingen forekomster av tinn nær de første produksjonsstedene, så dette må ha blitt fraktet dit fra andre land langt unna.
        
        I Norge kom de første bronseøksene til landet først et par tusen år etter at bronse var oppfunnet i Midt-Østen, ca. år 2000 før Kristus. Det at mennesker lærte seg kjemisk teknologi og lagde bronse førte til store endringer i livene til mennesker som levde den gangen, og ble så viktig at en hel tidsalder har fått navnet bronsealderen.`,
        'credit': `Bilde 1: Arkeologisk museum, Universitetet i Stavanger, CC BY-NC-ND`
    },
    '03': {
        'title': `Jernalderen`,
        'preface': `Jernalderen er navnet på tidsepoken som kommer etter bronsealderen fra ca. år 1500 f.Kr. i Midtøsten, og fra cirka 500 f.Kr. i Norge. I jernalderen lærte mennesker å lage rent jern fra jernmalm. Siden jernmalm er lettere å finne enn kobber tok jern over som det mest populære metallet til redskaper og våpen.`,
        'bodyText': `Jern finnes ikke i ren form i naturen. Det er fordi jern reagerer så lett med andre stoffer, som for eksempel oksygen. Derfor finnes det meste av jernet på jorda som jernmalm. For å omdanne jernmalm til jern trengs det en kjemisk reaksjon med et stoff som kan fjerne oksygen fra malmen. Dette lærte mennesker seg å gjøre i jernalderen, men uten at de forklarte det som en kjemisk reaksjon slik vi gjør i dag. Jernproduksjon ble gjort ved å varme opp jernmalm sammen med kull i spesielle ovner. Kullet reagerte med oksygenet i jernmalmen til karbondioksid og resultatet ble rent jern.

        Basert på arkeologiske funn og studier av kulturer ulike steder i verden kan det se det ut til at det å lage jern ble gjort av menn. Men kvinner kunne også bidra. I tradisjonell jernproduksjon i Etiopia er kvinner for eksempel med på å gjøre malmen klar til smelting, lage trekull eller blåse luft inn i ovnen gjennom svære rør for å få opp temperaturen.`,
        'credit': `Bilde 1: Mjøsmuseet, CC BY-NC-ND`
    },
    '04': {
        'title': `Urstoffene Jord, luft, ild og vann`,
        'preface': `For cirka 2000 år siden delte grekerne Empedokles og Aristoteles alle stoffer i verden inn i fire kategorier; jord, luft, ild og vann. Dette ble en viktig idé som folk trodde på i mange hundre år.`,
        'bodyText': `I antikken kom de første forklaringene på hva stoffer var og hvorfor stoffer kunne forandre seg. Antikken var en viktig tidsepoke i Hellas som begynte rundt 1000 år før Kristus og varte til ca. 500 etter Kristus. På denne tiden var det mange tenkere i Hellas som beskrev naturen og som forsøkte å finne forklaringer på forskjellige fenomener. Noen av ideene som ble tenkt den gangen ble viktige i hundrevis av år etter. Ideen om urstoffer er en slik viktig idé.

        For å forklare stoffene og endringene som kunne gjøres med dem delte grekeren Empedokles alle stoffer i verden inn i fire urstoffer; jord, luft, ild og vann. Aristoteles levde etter Empedokles, og han forbedret ideen om urstoffene. Aristoteles la til fire egenskaper til urstoffene; varm, tørr, kald og våt. Ifølge Aristoteles hadde alle urstoffene to av egenskapene, og stoffer kunne forvandles til hverandre ved å variere mengden av egenskapene.`,
        'credit': `Bilde 1: Wikimedia commons, CC
        Bilde 2: Wikimedia commons, CC
        Bilde 3: Wikimedia commons, CC`
    },
    '05': {
        'title': `Al-Razi beskriver apparater og kjemiske prosesser`,
        'preface': `De første kjemikerne vi kjenner til var alkymister. En alkymist var en person som hadde stor kunnskap om stoffer og hvordan man kunne endre dem. Alkymistene la grunnlaget for det som har blitt kjemikernes arbeidsmåter gjennom å utvikle metoder og oppdage nye stoffer.`,
        'bodyText': `Gjennom historien har særlig arabiske alkymister vært viktige for utviklingen. De var dyktige på laboratoriet og var de første som beskrev stoffer som saltsyre og svovelsyre, salpetersyre, soda (natriumkarbonat) og pottaske (kaliumkarbonat). Muhammad ibn Zakariya Al-Razi er én av de store alkymistene fra den arabiske gullalderen. Han var også lege.

        Da araberne utvidet sitt rike på 600-tallet, samlet de og videreutviklet kunnskap fra sivilisasjoner som Egypt, Hellas og India. Alkymistisk kunnskap spredte seg videre fra den arabiske verden til vesten. Al-Razi, eller Rhazes, var født i nærheten av Teheran i dagens Iran ca. 864 og døde ca. år 925. Han ga ut mer enn 200 bøker, blant annet et medisinsk leksikon som ble et viktig referanseverk. Han var sjefslege i Bagdad og skal ha vært den første som kunne skille mellom sykdommene kopper og meslinger.
        
        En av hans mest kjente bøker i alkymi er Sirr al-Asrar, som betyr «hemmelighetenes hemmelighet». Her forklarer Al-Razi i detalj hvordan alkymistiske prosesser foregår. Han skriver detaljert og klart om utstyr og ingredienser som også har vært viktig i kjemisk arbeid i senere tid.
        
        Al-Razi beskriver instrumenter og apparater som ovn, glassapparater, smeltedigel, sil og mange flere. Han beskriver også prosesser som smelting av metaller, blanding av metaller (legeringer), destillasjon og filtrering.`,
        'credit': `Bilde 1: Science History Images / Alamy Stock Photo_HRP743`
    },
    '06': {
        'title': `De første norske gruver`,
        'preface': `I Norge begynte gruvedriften på 1500-tallet.`,
        'bodyText': `Det finnes spor fra av metallutvinning i Norge helt tilbake til 1200-tallet, men de første ordentlige gruvene ble startet først fra 1500-tallet. Gullnes gruve i Seljord i Telemark regnes som den første gruven i Norge. Gruven startet opp i 1524 og var i drift til 1550-årene. I denne gruven var det sølv og kobber, og det var tyske gruvearbeidere som jobbet i gruven.
        
        Blant andre tidlige gruver i Norge var sølvgruva i Kongsberg som startet opp i 1623, mens kobbergruva i Løkken i Trøndelag var i drift fra 1654.`,
        'credit': `Bilde 1: Arbeiderbevegelsens arkiv og bibliotek_ CC BY NC ND 3.0`
    },
    '07': {
        'title': `Oppdagelsen av fosfor`,
        'preface': `Alkymisten Hennig Brand trodde han fant De vises sten, men det han fant var et nytt grunnstoff.`,
        'bodyText': `Hennig Brand var en alkymist som bodde i byen Hamburg i Tyskland. I arbeidsrommet sitt hadde han glasskolber og ovner slik at han kunne varme opp stoffer og forandre dem. I 1669 oppdaget Brand et helt nytt stoff da han kokte inn og varmet opp store mengder urin. Stoffet som var igjen i kolben lyste av seg selv med et magisk hvitt lys. Brand skal ha trodd at stoffet var De vises sten, og han holdt oppdagelsen hemmelig. 

        De vises sten var et stoff som skulle ha magiske egenskaper, og som kunne forvandle uedle stoffer til gull eller sølv. 1600-tallet regnes som en overgangstid mellom alkymi og det som utviklet seg til den kjemien vi kjenner i dag, og flere lærde menn/vitenskapsmenn regnet seg som alkymister. I dag vet vi at stoffet som Brand oppdaget er grunnstoffet fosfor.`,
        'credit': `Bilde 1: Joseph Wright of Derby, offentlig eie, via Wikimedia Commons`
    },
    '08': {
        'title': `Dorothea Juliana Wallich og koboltmineralene`,
        'preface': `Oppdagelser er ofte et resultat av kunnskap som er utviklet gjennom mange år. Koboltmineraler var kjent lenge før man visste at kobolt var et metall. Kymikeren Dorothea Juliana Wallich eksperimenterte med koboltmineraler utvunnet fra gruvene der og oppdaget at mineralene skiftet farge under oppvarming.`,
        'bodyText': `Wallich kunne gjøre slike eksperimenter fordi hun kom fra en privilegert familie i Sachsen, i det som i dag er Tyskland, der faren jobbet for hertugen. På denne tiden var det ikke mulig for kvinner å ta utdanning ved universiteter og lignende.

        Wallich er en av mange kymikere (tidlige kjemikere) som var opptatt av alkymi. I 1705 og 1706 skrev hun tre bøker om arbeidet med koboltmineralene. Hun mente at hun hadde funnet en viktig ingrediens som var nødvendig for å lage det magiske stoffet De vises sten, som kunne brukes til å omdanne uedle metaller til sølv og gull.`,
        'credit': `Bilde 1: Арсенич / Wikimedia Commons`
    },
    '09': {
        'title': `Metallet kobolt oppdages`,
        'preface': `Gruvekjemikeren Georg Brandt i Sverige var trolig den første som hentet ut kobolt fra koboltmineraler og beskrev det som et nytt metall. Dette gjorde han ved å smelte ut rent koboltmetall av malmen, blant annet ved å varme den opp med kull. Året var 1735.`,
        'bodyText': `Det var ikke nok å gjøre eksperimenter og beskrive dem nøye, for mange mente det ikke var et nytt metall Brandt hadde funnet. Derfor måtte han gjøre systematiske undersøkelser av mange ulike mineraler og metaller, slik at andre skulle forstå at kobolt var et helt eget metall med egenskaper ingen kjente metaller hadde.

        Koboltmineraler var ettertraktet fordi de kunne brukes til å lage blått glass. Nesten førti år etter at Georg Brandt oppdaget koboltmetall, ble det funnet koboltforekomster på Modum i Norge. Blaafarveværket ble etablert i 1776. På 1800-tallet leverte verket kobolt til hele verden.`,
        'credit': `Bilde 1: Gunnmnisja / Wikimedia Commons, CC-BY-SA-3.0
        Bilde 2: Cooper Hewitt, Smithsonian Design Museum, CC0 1.0`
    },
    '10': {
        'title': `Jacob Berzelius og mineralanalysene`,
        'preface': `Svenske J. Jacob Berzelius var en av 1800-tallets mest innflytelsesrike kjemikere. Han videreutviklet analysemetoder for å analysere mineraler og oppdaget grunnstoffene cerium (i 1803), selen (i 1818) og thorium (i 1828). Han er også kjent for å ha innført de kjemiske symbolene vi i dag bruker for grunnstoffene (for eksempel Na og K).`,
        'bodyText': `For å finne ut hva mineralene bestod av, gjorde Berzelius utallige undersøkelser for å bestemme egenskapene til mineralstoffene. Oppløsning, utfelling, filtrering, fordampning og veiing var viktige metoder i disse mineralanalysene, og hver prosess ble gjentatt en rekke ganger for å være sikker på at resultatet ble riktig.

        Komponentene i mineralene kunne skilles fra hverandre ved å tilsette kjente stoffer som bare reagerte med noen av mineralstoffene. Berzelius og andre kjemikere ble etter mye erfaring flinke til å skille fargereaksjoner fra hverandre, samt bruke lukt, smak og hørsel for å identifisere stoffer.
        
        Berzelius brukte også et instrument som har blitt kalt «kjemikerens stetoskop» i sine analyser, nemlig blåserøret. Røret ble brukt til å blåse luft gjennom flammen fra et stearinlys eller en brenner og rett på en bitteliten mengde av mineralet som skulle undersøkes, slik at det ga karakteristiske flammefarger eller fremkalte karakteristiske reaksjoner. Blåserøret var også praktisk å ha med seg når kjemikere skulle undersøke mineraler ute i naturen.`,
        'credit': `Bilde 1:
        Foto: Thuresson / Wikimedia Commons, CC
        Bilde 2:
        Foto: Annette Lykknes / Kungliga Vetenskapsakademien i Stockholm
        Bilde 3:
        Foto: Annette Lykknes / Kungliga Vetenskapsakademien i Stockholm`
    },
    '11': {
        'title': `Oppdagelsen som fylte et hull i periodesystemet`,
        'preface': `Da den russiske kjemikeren Dmitrij Mendelejev laget sitt periodesystem i 1869 satte han av åpne plasser til grunnstoffer som ennå ikke var oppdaget. I 1875 fant franskmannen Paul-Émile Lecoq de Boisbaudran et av de manglende grunnstoffene. Det ble kalt gallium.`,
        'bodyText': `Siden Mendelejev hadde funnet at det var noen egenskaper som gjentok seg i periodesystemet var han helt sikker på at det ville bli oppdaget et nytt grunnstoff på plassen under aluminium. Han var så sikker at han til og med foreslo hvilke egenskaper det nye grunnstoffet ville ha, som at det ville ha atomvekten 69.

        Franskmannen Paul-Émile Lecoq de Boisbaudran var en av pionerene i en metode som ble kalt spektroskopi, og han skrev den første læreboken i spektroskopi på fransk. I 1875 studerte han en bit sinkmalm fra Pyreneene med spektroskop, og han så en lilla linje i fargespekteret som ikke var kjent fra andre grunnstoffer. Han skjønte derfor at det skjulte seg et nytt grunnstoff i sinkmalmen. Mot slutten av året klarte han å isolere det nye grunnstoffet, som var et sølvfarget metall med atomvekt 68.  Gallium er et veldig spesielt metall fordi det har så lavt smeltepunkt at det smelter i hånda.
        
        Boisbaudran gav grunnstoffet navnet gallium etter det latinske navnet for Frankrike (Gallia). Men noen mener at de Boisbaudran gav grunnstoffet navn etter seg selv, siden han også hadde navnet Lecoq, som betyr hane, eller gallus på latin.
        
        Mendelejev forutsa også egenskaper for andre uoppdagede grunnstoffer. Flere stemte godt med egenskapene til grunnstoffer som senere ble funnet, mens andre viste seg å være feilslåtte.`,
        'credit': `Bilde 1: Wikimedia Commons, CC BY-SA 3.0`
    },
    '12': {
        'title': `Mineralanalyser på 1900-tallet`,
        'preface': `Marie og Pierre Curie oppdaget de to radioaktive grunnstoffene polonium og radium i 1898. Stoffene ble påvist på grunn av sin karakteristiske stråling.`,
        'bodyText': `Senere ble de påvist ved hjelp av sine unike linjespektre. Det endelige beviset for at et nytt grunnstoff var oppdaget, var bestemmelsen av en unik atomvekt, som var et omfattende kjemisk analysearbeid.  Mineralanalyse var en vanlig metode fram til avanserte instrumenter gradvis tok over fra midt på 1900-tallet.

        For å bestemme atomvekten til radium, måtte ekteparet Curie og deres medarbeidere ha en tilstrekkelig mengde stoff å arbeide med. Sammen med sine samarbeidspartnere analyserte de flere tonn med uranmineral, tilsatte stoffer som reagerte med noen av ingrediensene i mineralet, og fortsatte så med å skille ut del for del til de nærmet seg den delen som inneholdt radium. Prosesser som oppløsning, utfelling, filtrering, fordampning og veiing ble utført og gjentatt flere ganger før de hadde utskilt 0,1 gram radiumsalt. Hele prosessen tok nesten fire år.
        
        Et annet forskerpar som arbeidet med mineralanalyser var de tyske kjemikerne Ida og Walter Noddack. De hadde fått påvist grunnstoffet rhenium ved hjelp av røntgenspektroskopi i 1925. Også for dem tok det fire år før de hadde produsert det første grammet av rhenium og kunne bestemmet grunnstoffets atomvekt.
        
        Det å gjøre nøyaktige mineralanalyser og bestemme atomvekter krevde veldig god kunnskap om kjemiske reaksjoner og trening i nøyaktighetsarbeid på laben. Ellen Gleditsch, som var Marie Curies norske elev fra 1907 til 1912, gikk for eksempel i opplæring hos Theodore Richards i USA. Richards var en av få mestere innenfor atomvektsbestemmelser i verden på den tiden.`,
        'credit': `Bilde 1: Petr Kratochvil / Publicdomainpictures.net, CC0
        Bilde 2: Photo New York Times. Source: Musée Curie (coll. ACJC)`
    },
    '13': {
        'title': `Robert Boyle kritiserer rådende grunnstoffteorier`,
        'preface': `I 1661 ga Robert Boyle ut en bok som ble kalt Den skeptiske kymikeren. I boken skrev han at urstoffene jord, luft, ild og vann ikke kunne være grunnstoffer.`,
        'bodyText': `Boyle argumenterte også mot ideen om at alle stoffer bestod av prinsippene kvikksølv (det flyktige), svovel (det brennbare) og salt. Forsøk viste nemlig at urstoffene og de tre prinsippene ikke ble dannet når stoffer ble brutt ned.  

        Boyle var en irsk kjemiker (kymiker) og alkymist som brukte store deler av sitt liv og sin formue til vitenskapelig arbeid. Han var med på å stifte et vitenskapsselskap i London i 1660 og gjorde blant annet en rekke forsøk for å studere egenskapene ved luft. (Om du har hørt om Boyles lov, er den et resultat av dette arbeidet.)
        
        Boken Den skeptiske kymikeren skulle bidra til å gjøre kjemien mer akseptabel for medlemmene av vitenskapsselskapet. Boyle ville få fram at kjemi ikke bare handlet om å lage medisiner eller andre produkter som kunne selges, men om å forstå og utforske naturen.
        
        Boyle tvilte i det hele tatt på at det fantes grunnstoffer som alle stoffer kunne lages av. I stedet mente han det fantes en enkelt urmaterie, partikler som klumpet seg sammen til små legemer (korpuskler) og dannet utgangspunkt for ulike stoffer på grunn av form, størrelse og bevegelse.`,
        'credit': `Bilde 1: Wellcome Images / Wikimedia Commons, CC-BY 4.0, Photo number V0023488
        Bilde 2: Gutenberg.org / Wikimedia Commons`
    },
    '14': {
        'title': `Vitenskapelige undersøkelser av ild`,
        'preface': `Ild ble i lang tid regnet som et av de fire urstoffene (jord, luft, ild, vann), men var fortsatt et mystisk fenomen på 1700-tallet. I 1738 skrev den franske naturfilosofen og adelskvinnen Emilie Du Châtelet en avhandling om ild som ble utgitt av det prestisjefylte franske vitenskapsakademiet. Hun ble den første kvinnen som fikk et arbeid publisert av akademiet.`,
        'bodyText': `På 1700-tallet var tilgang på universitetsutdanning for kvinner begrenset. Farens stilling ved kongens hoff gjorde det mulig for Emilie og brødrene å få privatundervisning i sport, språk, matematikk, naturfilosofi og litteratur. Hun fortsatte å få undervisning av store vitenskapsmenn etter at hun giftet seg og fikk barn, og var oppdatert på det som rørte seg innenfor naturfilosofien og matematikken. Blant annet oversatte og kommenterte hun Newtons store verk Principia Mathematica. 

        Avhandlingen om ild ble skrevet til en konkurranse om å forstå ildens natur. Ild var en av de fire urstoffene, men var også interessant for naturfilosofene i det franske akademiet fordi det ble brukt til å analysere hvordan stoffer ble brutt ned.  
        
        Emilie Du Châtelet studerte ild gjennom hvordan den kom til uttrykk; gjennom lys, farge og varme. Hun så også på hva som skjedde gjennom avkjøling, altså når ild ikke var til stede. Du Châtelet bidro til faglige diskusjoner ved åpent å se på ulike forklaringer på fenomenet ild og ikke binde seg til én rådende teori.  
        
        I dag studeres ild som en del av en vitenskapsgren som kalles termodynamikken. Dette fenomenet ble ikke ordentlig forstått før hundre år etter Emilie Du Châtelets arbeid. Ild regnes ikke lenger som et grunnstoff.`,
        'credit': `Bilde 1: Maurice Quentin de La Tour, offentlig eie, via Wikimedia Commons`
    },
    '15': {
        'title': `Oppdagelsen av oksygen og den nye kjemien`,
        'preface': `Tidlig på 1700-tallet ble luft fortsatt sett på som ett, enhetlig urstoff, og ikke sammensatt av flere stoffer slik vi tror i dag. Utover århundret ble det mulig å samle opp og studere luft i spesielle beholdere. Ved å gjøre tester kunne naturfilosofene skille mellom ulike typer luft, eller gasser som vi kaller dem i dag. Oksygen var en av gassene som ble oppdaget på 1770-tallet. Den franske kjemikeren Antoine Lavoisier bygget en helt ny kjemisk teori basert på hvordan oksygen deltar i kjemiske reaksjoner.`,
        'bodyText': `Den britiske naturfilosofen Joseph Priestley oppdaget, undersøkte og karakteriserte en rekke gasser, blant annet det vi i dag kjenner som lystgass (dinitrogenoksid) og ammoniakk. Han studerte også det som ble kalt «bunden luft», karbondioksid. Denne gassen var vond å puste i for dyr.  

        Priestley oppdaget at det også fantes en type luft som hadde motsatt effekt; den fikk lys til å brenne med sterk flamme og mus til å leve lenger enn i vanlig luft. På denne tiden ble kjemiske reaksjoner som forbrenning forklart ved hjelp av noe som ble kalt «flogiston» - det brennbare prinsipp. Priestley kalte den nye lufta «deflogistisert luft» fordi den var fri for flogiston. Svensken Carl Wilhelm Scheele hadde gjort en lignende oppdagelse og kalte lufta for «ildluft».  
        
        I Paris gjentok Antoine-Laurent Lavoisier forsøket til Priestley, der den nye lufta ble dannet når et stoff som ble kalt rød kvikksølvkalk ble varmet opp. Lavoisier hadde over tid gjort observasjoner som fikk ham til å tvile på flogistonteorien. Den nye gassen, som Lavoisier kalte oksygen, dannet utgangspunkt for en helt ny kjemisk teori om forbrenning og respirasjon. Sammen med noen kolleger lanserte han også et helt nytt kjemispråk, der navn som vi bruker i dag, som oksygen og hydrogen, ble innført.`,
        'credit': `Bilde 1: Jacques-Louis David, offentlig eie, via Wikimedia Commons
        Bilde 2: Wikimedia Commons, offentlig eie`
    },
    '16': {
        'title': `En ny kjemi og en ny forståelse av grunnstoff`,
        'preface': `Den franske kjemikeren Antoine-Laurent Lavoisier er kjent for å ha modernisert kjemien på slutten av 1700-tallet. Han lanserte en ny teori for forbrenning, systematiserte bruken av vektmålinger i kjemien, innførte et nytt kjemispråk og en ny definisjon av grunnstoff.`,
        'bodyText': `For Lavoisier var et grunnstoff eller et ikke-sammensatt stoff et stoff som ikke kunne brytes ned videre ved hjelp av kjemiske metoder. Denne definisjonen ble presentert i et læreverk i 1789.

        Noen år tidligere hadde Lavoisier og kolleger demonstrert foran et stort publikum at vann kunne dannes fra hydrogengass og oksygengass og dermed ikke kunne være et grunnstoff, slik den gamle urstoffteorien sa.
        
        Demonstrasjonsforsøkene krevde månedsvis med forberedelser og spesiallagde apparater bestilt fra instrumentmakere. For å overbevise publikum gjorde Lavoisier og kollegene tester for å vise at det virkelig var vann som ble dannet, og at utgangsstoffene var hydrogengass og oksygengass. Demonstrasjonene i seg selv varte i flere timer og ble gjentatt flere ganger i løpet av tre år.
        
        Ikke alle som så eller hørte om forsøkene ble overbevist med en gang. For noen tok det tid, mens andre holdt på sine gamle ideer til de døde. Over tid utviklet den franske kjemien seg til å bli den rådende måten å forstå den kjemiske verden på.`,
        'credit': `Bilde 1: Wikimedia Commons, offentlig eie`
    },
    '17': {
        'title': `Systematisering av grunnstoffer til periodesystemer`,
        'preface': `På 1860-tallet ble det laget flere periodesystemer for de litt over 60 grunnstoffene som da var kjent. Den russiske kjemikeren Dmitrij Mendelejev sin tabell fra 1869 er det mest kjente periodesystemet. Lothar Meyer fra Tyskland laget også en tabell, mens franske Alexandre-Émile Béguyer de Chancourtois systematiserte grunnstoffene i form av en spiral.`,
        'bodyText': `Både Mendelejev og Meyer underviste kjemi på universitetet, og var i gang med å skrive lærebøker. De ønsket å skrive om grunnstoffenes kjemi på en systematisk måte, i stedet for å gå gjennom ett og ett grunnstoff slik lærebøkene ofte gjorde på 1800-tallet. Med over 60 grunnstoffer ville det bli kaotisk og uoversiktlig.

        Det hadde vært flere forsøk på å finne sammenhenger mellom egenskapene til grunnstoffene før arbeidene til Mendelejev, Meyer og Béguyer de Chancourtois, alle med utgangspunkt i at hvert grunnstoff har en unik atomvekt. Men arbeidet med å bestemme atomvektene var krevende, og kjemikerne var ikke enige om hvilke atomvekter som var riktige.
        
        Etter historiens første kjemikongress i den tyske byen Karlsruhe i 1860 kom kjemikerne fram til et samlet system for atomvekter. Det var et viktig grunnlag for å kunne systematisere grunnstoffene.
        
        Felles for systemene til Mendelejev, Meyer og Béguyer de Chancourtois (og andre forsøk på systematisering) var at grunnstoffene var plassert i rekkefølge etter økende atomvekt. Samtidig ble grunnstoffene plassert slik at de som hadde felles egenskaper ble gruppert sammen. I tabellformen av systemet havnet grunnstoffer med liknende egenskaper under hverandre, slik de også er plassert i dag. Egenskapene gjentok seg altså periodisk, derav navnet periodesystemet.`,
        'credit': `Bilde 1: Wikimedia Commons, offentlig eie
        Bilde 2: Wikimedia Commons, offentlig eie`
    },
    '18': {
        'title': `Verdens første batteri, Voltasøylen`,
        'preface': `Voltasøylen gjorde det mulig å lage elektrisitet fra kjemiske reaksjoner.`,
        'bodyText': `Verdens aller første batteri ble oppfunnet i 1800 av den italienske fysikeren Alessandro Volta. Batteriet ble kalt for voltasøylen og det bestod av en høy stabel med par av to ulike metallskiver av sølv og sink. Mellom hvert par av metallskiver var det lagt en papirskive dynket i saltvann.

        Voltasøylen ble veldig viktig for å oppdage nye grunnstoffer. Det er fordi elektrisitet kan brukes til å skille grunnstoffer som er bundet til hverandre i salter. Batteriet ble dermed et kraftig analyseverktøy i kjemi.
        
        Voltasøylen bygde på oppdagelsen til en annen italiensk vitenskapsmann, Luigi Galvani. I laboratoriet til Galvani ble det oppdaget at føttene til en død frosk sprellet hvis kroppen var i kontakt med to ulike metaller. Galvani mente dette skyldtes at det var en slags dyrisk elektrisitet i kroppen til frosken. Volta var først enig i dette, men så begynte han å gjøre egne undersøkelser av fenomenet. Volta oppdaget at det ikke var frosken som var opphavet til elektrisiteten, men at det holdt at to forskjellige metaller var i kontakt med hverandre. Dette brukte han til å lage det aller første batteriet.`,
        'credit': `Bilde 1: infinitiv.no.
        Bilde 2: Per-Odd Eggen, NTNU.`
    },
    '19': {
        'title': `Grunnstoffene natrium og kalium isoleres ved hjelp av elektrolyse`,
        'preface': `I 1807 brukte den engelske vitenskapsmannen Humphry Davy voltasøylen til å oppdage grunnstoffene kalium og natrium.`,
        'bodyText': `Metoden med å skille grunnstoffer fra hverandre ved hjelp av elektrisitet kalles elektrolyse. Humphry Davy var den første som brukte denne metoden til å oppdage nye grunnstoffer. 

        Grunnstoffet kalium finnes ikke i ren form i naturen, det er som regel bundet til andre grunnstoffer i salter. Davy oppdaget grunnstoffet ved å lede strøm fra voltasøylen gjennom en litt fuktig klump av stoffet pottaske (kaliumkarbonat). Da la han merke til at det ble laget små metalliske kuler ved den ene elektroden. Noen av kulene begynte å brenne så fort de ble dannet. Davy forstod etter hvert at han hadde oppdaget et nytt grunnstoff, og han gav det navnet potassium på engelsk, fra navnet på stoffet det var utvunnet fra. På norsk kaller vi stoffet kalium.
        
        Davy oppdaget også grunnstoffet natrium med samme metode, også i 1807. Og året etter brukte han voltasøylen til å oppdage grunnstoffene magnesium, kalsium, strontium og barium.
        
        Humphry Davy ble berømt for oppdagelsene sine, men han var også kjendis fordi han holdt spektakulære forelesninger med kule eksperimenter. I videoen kan du se en gjenskaping av en av eksperimentene Davy viste fram for publikum. Her brukte han kalium, et av grunnstoffene han selv hadde oppdaget, til å lage en ildsprutende vulkan.`,
        'credit': `Bilde 1: Wikimedia commons, CC
        Bilde 2: Bildet er hentet fra A- Guerout, La lumière électrique 11 (1884).`
    },
    '20': {
        'title': `Oppdagelsen av cesium og rubidium`,
        'preface': `På 1800-tallet ble det oppfunnet nye instrumenter og metoder som gjorde det mulig å oppdage flere grunnstoffer. En av de viktige oppfinnelsene var flammespektroskopet til de to tyske vitenskapsfolkene Robert Wilhelm Bunsen og Gustav Robert Kirchhoff.`,
        'bodyText': `Bunsen og Kirchoff oppfant flammespektroskopet i 1859. Dette instrumentet gjorde det mulig å bestemme grunnstoffer ved å studere lyset fra flammen til stoffene når de brant. Dette kalles spektroskopi. Hvert eneste grunnstoff sender ut forskjellige lys når de brenner, og dette er et slags fingeravtrykk for grunnstoffer. Da Bunsen og Kirchoff brukte flammespektroskopet til å se på innholdet av ulike stoffer i mineralvann fra en kilde i 1860 oppdaget de to grunnstoffer som ikke var kjent fra før. De gav de to nye grunnstoffene navnene cesium og rubidium etter de latinske navnene på lysspektrene fra stoffene; cesium har blått lysspekter, mens rubidum har et dypt rødt lysspekter.

        Cesium og rubidium var de første grunnstoffene som ble oppdaget med hjelp av spektroskopi, og snart kom det mange flere: I 1861 oppdaget den engelske fysikeren og kjemikeren William Crookes et grunnstoff med grønt spekter. Det fikk navnet thallium, som betyr «grønn grein». Indium var det fjerde grunnstoffet som ble oppdaget med spektroskopi. Det ble oppdaget i 1863 av tyske Ferdinand Reich. Indigo er oppkalt etter sitt lilla fargespekter.`,
        'credit': `Bilde 1: Illustrasjon Science History Institute, offentlig eie.
        Bilde 2: Didaktische.Medien, CC BY-SA 3.0 via Wikimedia Commons`
    },
    '21': {
        'title': `Oppdagelsen av et nytt grunnstoff på sola`,
        'preface': `18. august 1868 var den franske astronomen Pierre Jules César Janssen i Guntur i India for å observere en solformørkelse. Da han rettet spektroskopet sitt mot den lysende ringen rundt den formørkede sola oppdaget han en gul linje i spekteret som ikke passet med noen kjente grunnstoffer. Selv om det ikke var grunnstoffer han lette etter, forstod han at han måtte ha oppdaget et nytt grunnstoff.`,
        'bodyText': `Janssen sendte et brev til det franske vitenskapsakademiet for å fortelle om oppdagelsen. Posten brukte lang tid i 1868, og tilfeldigvis kom brevet til Janssen fram samme dag som brevet fra en annen astronom, engelske Norman Lockyer. To måneder etter Janssen hadde Lockyer nemlig også oppdaget den gule linja i spekteret ved å studere lys fra sola i London. Siden vitenskapsakademiet fikk brevene deres samme dag fikk begge to æren for oppdagelsen. Det var Norman Lockyer som gav navn til det nye grunnstoffet. Han kalte det helium, etter den greske solguden Helios, fordi grunnstoffet først ble oppdaget på sola. Det tok enda flere år før helium også ble funnet på jorda.  

        Pierre Janssen fortsatte forresten å studere solformørkelser over hele verden, og han oppfant også teknikker for å ta bilder av astronomiske fenomener mens de skjedde, et slags revolver-kamera som kunne ta mange bilder veldig raskt. Hans oppfinnelse ble grunnlag for filmen. Denne filmen (se video) som Pierre Janssen har satt sammen av fotografier av planeten Venus som krysser solskiva er verdens aller eldste kjente film.`,
        'credit': `Bilde 1: Atelier Nadar_Wikimedia commons, CC
        Bilde 2: Luc Viatour, CC BY-SA 3.0 via Wikimedia Commons
        Bilde 3: Jurii, CC BY 3.0 via Wikimedia Commons`
    },
    '22': {
        'title': `Dovne gasser i lufta`,
        'preface': `I 1894 fant de to engelskmennene Lord Rayleigh (John William Strutt) og Willam Ramsay en hittil uoppdaget gass som utgjorde omtrent 1% av gassene i lufta. De kalte det nye grunnstoffet argon, som betyr doven, siden det ikke reagerte med andre stoffer.`,
        'bodyText': `Vitenskapsmannen William Crookes brukte spektroskopi for å vise at det nye stoffet som Rayleigh og Ramsay hadde funnet hadde et spekter som ikke var sett før. Mange andre vitenskapsfolk på deres tid var likevel ikke enig i at dette var et nytt grunnstoff. Noen mente for eksempel at det de hadde funnet i stedet var en variant av grunnstoffet nitrogen, kanskje tre nitrogenatomer som var bundet sammen i en forbindelse.

        I 1895 oppdaget Ramsay også gassen helium på jorda for første gang. Dette grunnstoffet var tidligere bare sett på sola. Ramsay fant at helium ble dannet i noen mineraler, og han kjente igjen spekteret av grunnstoffet som Pierre Janssen hadde oppdaget på sola omtrent tjue år før. Ramsay så at helium og argon oppførte seg likt, de var begge gasser som ikke reagerte med andre stoffer. Han begynte derfor å tenke at det fantes en hel ny gruppe i periodesystemet med flere lignende grunnstoffer.
        
        Sammen med kollegaen Morris W. Travers begynte Ramsay å lete etter flere av disse «dovne» og ikke-reaktive stoffene i luft. I løpet av noen måneder i 1898 fant de hele tre flere grunnstoffer i samme familie, som alle var gasser; krypton, neon og xenon. De brukte spektroskopi for å vise at gassene var nye grunnstoffer som skilte seg fra andre kjente stoffer.`,
        'credit': `Bilde 1: Elliott & Fry, offentlig eie, via Wikimedia Commons.
        Bilde 2: Pslawinski, CC BY-SA 2.5 via Wikimedia Commons.
        Bilde 3: Geoff Henson, Flickr, CC BY-ND 2.0`
    },
    '23': {
        'title': `Røntgenspektroskopi og grunnstoffoppdagelser`,
        'preface': `I 1913, 18 år etter at røntgenstrålene ble oppdaget, fant en ung engelskmann ved navn Henry Moseley at hvert grunnstoff produserte unike røntgenspektre, og at disse kunne brukes til å identifisere grunnstoffer. Metoden var mer treffsikker enn vanlig spektroskopi og gikk under navnet «fingeravtrykksmetoden».`,
        'bodyText': `I 1911 var det kjent at atomet bestod av negativt ladde elektroner og en positivt ladet kjerne, men fysikerne visste ikke hva kjernen bestod av. (Protonet ble oppdaget noen år etterpå.) To år senere foreslo nederlenderen Anton van den Broek at kjerneladningen økte med ett tall for hvert grunnstoff i periodesystemet. Dette «serienummeret» på grunnstoffene fikk snart navnet atomnummer. 

        Den unge fysikeren Henry Moseley ville teste van den Broeks idé. Han konstruerte et apparat der han kunne sende en strøm av elektroner mot en rekke roterende metallplater, en etter en. Røntgenspektrene som ble produsert fra hvert enkelt metall var unike, og ikke bare det: Moseley bekreftet at van den Broeks atomnummer var det samme som grunnstoffatomets kjerneladning, og han viste sammenhengen med røntgenspektrene matematisk. 
        
        Siden hvert grunnstoff hadde et unikt atomnummer, kunne røntgenspektre brukes til å finne ut om nyoppdagede stoffer virkelig var grunnstoffer. På denne måten ble stoffer som vitenskapsfolk hadde trodd var grunnstoffer, for eksempel nipponium, coronium og nebullium avskrevet som falske.  
        
        Røntgenspektroskopi ble også en overbevisende metode for å bekrefte nye grunnstoffoppdagelser. Ida og Walter Noddack i Tyskland annonserte for eksempel i 1925 at de hadde røntgenspektroskopiske bevis for å ha funnet grunnstoff nr. 43, masurium og grunnstoff nr. 75, rhenium. 
        
        For å kunne overbevise vitenskapssamfunnet måtte imidlertid flere bevis legges på bordet. For rhenium klarte ekteparet Noddack etter noen år å produsere et helt gram av det rene grunnstoffet, slik at grunnstoffets unike atomvekt kunne bestemmes. Dette førte til at Ida og Walter Noddack ble anerkjent som oppdagere av rhenium. Masurium ble aldri anerkjent som grunnstoffnavn, for ekteparet klarte ikke å komme med dokumentasjon for sine funn, og ingen andre kunne heller bekrefte at grunnstoffet fantes i mineralprøvene. Grunnstoffet ble senere produsert kunstig og fikk navnet technetium.`,
        'credit': `Bilde 1: Wikimedia Commons, offentlig eie
        Bilde 2: KU Leuven Universiteitsarchief, Archives of Walter and Ida Noddack-Tacke, 51
        Bilde 3: Ida Noddack, Angewandte Chemie, 1934
        Bilde 4: KU Leuven Universiteitsarchief, Archives of Walter and Ida Noddack-Tacke, 52`
    },
    '24': {
        'title': `Radioaktivitet og grunnstoffene polonium og radium`,
        'preface': `I 1896 oppdaget den franske vitenskapsmannen Henri Becquerel ved en tilfeldighet at uranmineraler gjorde fotografiske plater svarte helt spontant. Dette kunne ikke forklares på noen annen måte enn at uranmineralene avga en ukjent type stråling. Fenomenet fikk navnet radioaktivitet. I 1898 oppdaget ekteparet Marie og Pierre Curie to nye grunnstoffer, polonium og radium, på grunn av sin spesielle stråling.`,
        'bodyText': `Henri Becquerel arbeidet ved Naturhistorisk museum i Paris, som hadde en stor mineralsamling. Han hørte om Wilhelm Conrad Röntgens oppdagelse av røntgenstråler i 1895. Flere spekulerte på om selvlysende stoffer også sendte ut røntgenstråler, og dette ville Becquerel undersøke.

        I samlingen sin hadde Becquerel uranmineraler, som var kjent for å sende ut klart og tydelig lys. Han bestemte seg for å teste uranmineralene for mulig røntgenstråling. Røntgenstrålene ville vise seg ved at en fotografisk plate som var plassert under steinen med uranmineralet ble svart.
        
        For at uranmineralene skulle lyse eller sende ut stråling, måtte de først ha blitt eksponert for sollys. En dag da det var overskyet, la Becquerel mineralet med den fotografiske platen, som til vanlig lå i vinduskarmen, i en skuff. Han bestemte seg likevel for å fremkalle platen noen dager senere, og oppdaget til sin overraskelse at platen var blitt svart av stråling. Han gjentok forsøket over tid, og skjønte etter hvert at han hadde oppdaget en helt ny type stråling, siden uranmineralene fortsatte å sende ut stråling selv etter flere måneder i skuffen.
        
        Ett år senere valgte den unge vitenskapskvinnen Marie Curie å fordype seg i dette nye fenomenet til sitt doktorgradsarbeid. Få hadde vært interessert i å studere det videre, og selv Becquerel hadde gått videre med annen forskning. Hun ga fenomenet navnet «radioaktivitet» som erstatning for «Becquerelstråler» som knyttet det til Becquerel alene.
        
        Marie Curie testet grunnstoff for grunnstoff, for å se om flere grunnstoffer enn uran avga stråling, og fant at thorium også gjorde det. Sammen med ektemannen Pierre Curie fant hun også to nye grunnstoffer som avga stråling. De befant seg i prøvene med uranmineral, men hadde mye mer intens stråling enn uran og thorium. Etter mange runder med kjemisk behandling, oppdaget Marie og Pierre Curie at det ene stoffet reagerte kjemisk ganske likt som barium, og dette grunnstoffet kalte de radium. Det andre grunnstoffet reagerte likt med vismut, og dette ble kalt polonium.
        
        Senere ble grunnstoffenes eksistens bekreftet med spektroskopi. Det tok ekteparet og deres medarbeidere deretter nesten fire år å gjøre analysene som gjorde det mulig å hente ut nok radium fra mineralprøven til å kunne bestemme dets unike atomvekt. Dette ble det endelige beviset for at radium var et grunnstoff.`,
        'credit': `Bilde 1: Nobelstiftelsen / Wikimedia Commons
        Bilde 2: Wikimedia Commons, offentlig eie
        Bilde 3: Tekniska museet / Wikimedia Commons`
    },
    '25': {
        'title': `Isotoper av grunnstoffer`,
        'preface': `I 1913 foreslo den britiske kjemikeren Frederick Soddy at grunnstoffer kunne finnes i ulike varianter. Disse variantene ble kalt isotoper, og hadde alt utenom atomvekten til felles. I 1914-15 bidro den østerrikske fysikeren Stefanie Horovitz og hennes veileder Otto Hönigschmid med bevis for at ideen om isotoper var sann.`,
        'bodyText': `Da periodesystemet først ble utviklet på 1860-tallet, var det basert på at alle grunnstoffer har en unik atomvekt. Gjennom radioaktivitetsforskningen tidlig på 1900-tallet ble det funnet mer enn 30 nye stoffer med unik atomvekt, men hvordan skulle alle disse få plass i periodesystemet?

        Det er i denne forbindelsen at Soddy foreslår at ett og samme grunnstoff kan ha mer enn én atomvekt. Etter en idé av sin venn, legen Margaret Todd, kaller han variantene av grunnstoffer for isotoper. Isotop kommer av gresk og betyr «samme plass». Isotopene hører nemlig til samme plass i periodesystemet – de hører til samme grunnstoff - men har ulik atomvekt.
        
        Selv om en idé synes god, er det ikke gitt at vitenskapssamfunnet vil godta den. Mange var skeptiske til Soddys isotoper, og det ble gjort flere forsøk på å vise at isotoper var reelle uten at vitenskapsfolk ble overbevist.
        
        Det første tydelige eksperimentelle beviset kom fra Horovitz og Hönigschmid. De klarte den omhyggelige oppgaven å separere ut bly som var igjen etter at radium var hentet ut fra mineralprøver. Radium brytes nemlig gjennom flere trinn ned til bly. Deretter bestemte de atomvekten til bly gjennom en nøyaktighetskrevende vektanalyse. Atomvekten til bly i prøvene de analyserte viste seg å være vesensforskjellig fra atomvekten til bly som ikke stammer fra radioaktiv nedbrytning, altså fantes minst to isotoper av bly.`,
        'credit': `Bilde 1: Wikimedia Commons, offentlig eie
        Bilde 2: Austrian Central Library for Physics`
    },
    '26': {
        'title': `Det mest sjeldne naturlige grunnstoffet`,
        'preface': `Astat er det mest sjeldne naturlige grunnstoffet på jorda, og det var derfor veldig vanskelig å oppdage i naturen. Grunnstoffet ble først laget kunstig i et laboratorium i USA i 1940. Litt senere ble forskerne Berta Karlik og Traude Bernert de første som oppdaget astat i en naturlig bergart.`,
        'bodyText': `Astat er et radioaktivt grunnstoff som hører til halogenene. Det er så sjeldent at det til enhver tid finnes bare cirka 40 milligram av det på hele jorda. Flere forskere forsøkte å være de første til å finne og identifisere astat, men mange av dem lyktes ikke. I Frankrike forsøkte forskerteamet Horia Hulubei og Yvette Cauchois å bruke røntgenspektroskopi for å identifisere astat. De mente de hadde funnet bevis for oppdagelsen, men andre forskere var ikke enige i dette.

        De første som til slutt fikk æren for oppdagelsen av astat var tre forskere som jobbet i USA; Emilio Segré, Dale R. Corson og Kenneth Ross MacKenzie. De lyktes med å lage grunnstoffet i 1940 ved å bombardere grunnstoffet vismut (83) med heliumkjerner slik at atomkjernene smeltet sammen til astat (85). Segré hadde også vært med på oppdagelsen av det aller første kunstige grunnstoffet, technetium, noen år tidligere.
        
        Første gang astat ble oppdaget fra en naturlig kilde var da de to forskerne Berta Karlik og Traude Bernert i 1942-43 fant at astat blir dannet i nedbryting av den radioaktive gassen radon.`,
        'credit': `Bilde 1: Double sharp, CC BY-SA 4.0 via Wikimedia Commons`
    },
    '27': {
        'title': `Technetium - det første kunstige grunnstoffet`,
        'preface': `Grunnstoffet technetium ble laget ved hjelp av fenomenet kunstig radioaktivitet. Det er det første kunstig framstilte grunnstoffet.`,
        'bodyText': `De to franske forskerne Irène Joliot-Curie og Frèdèric Joliot-Curie oppdaget fenomenet kunstig radioaktivitet i 1934. Det skjedde i eksperimenter der de sendte kjernepartikler mot aluminiumatomer i en tynn folie. Da oppdaget de at det ble dannet en ny og ukjent variant av grunnstoffet fosfor som selv var radioaktivt og sendte ut stråling. De hadde klart å gjøre et ikke-radioaktivt grunnstoff radioaktivt! Allerede året etter fikk ekteparet Joliot-Curie Nobelprisen i kjemi for denne oppdagelsen.

        I 1937 fant forskerne Carlo Perrier og Emilio Segré et nytt grunnstoff som var dannet med hjelp av fenomenet kunstig radioaktivitet. Grunnstoffet var blitt laget kunstig i en partikkelakselerator i Berkeley i USA der en folie av grunnstoffet molybden (nummer 42) var blitt bombardert med nøytroner, slik at de «smeltet sammen» og dannet en ny og tyngre atomkjerne med atomnummer 43. Det nye grunnstoffet fikk navnet technetium, som betyr kunstig.`,
        'credit': `Bilde 1: Photo Henri Manuel. Source : Musée Curie (coll. ACJC)`
    },
    '28': {
        'title': `Oppdagelsen av oganesson, grunnstoff nummer 118`,
        'preface': `Et team av 30 forskere fra Russland og USA gjorde oppdagelsen av oganesson sammen i 2006. Oganesson er det foreløpig siste grunnstoffet i periodesystemet, og det har nummer 118. Oganesson finnes ikke i naturen, men er laget kunstig i et laboratorium ved å smelte sammen to atomkjerner.`,
        'bodyText': `Grunnstoffet har fått navn etter den russisk-armenske forskeren som ledet forskerteamet som oppdaget det, Jurij Oganesian. En av de amerikanske forskerne som var med på oppdagelsen av oganesson er Dawn Shaughnessy. Hun ble blant annet interessert i vitenskap gjennom å se Star Wars-filmer og lese science fiction-bøker som barn. Shaughnessy har vært med på å oppdage grunnstoffene flerovium, moscovium, livermorium og tenness i tillegg til oganesson. Alle disse er supertunge grunnstoffer som er laget av mennesker.

        Grunnen til at det er så vanskelig å oppdage nye grunnstoffer som oganesson, er at forskerne må lage dem fra grunnen av ved å få atomer til å smelte sammen (ved en prosess som kalles fusjon). Det kan bare skje i noen helt spesielle laboratorier på noen få steder i verden og ved hjelp av avanserte og dyre instrumenter som blant annet partikkelakseleratorer. Ett av disse laboratoriene er i Dubna i Russland, der oganesson ble oppdaget. Forskerteamet av russiske og amerikanske forskere lagde oganesson ved å skyte atomkjerner av grunnstoffet kalsium mot en tynn folie laget av kaliforniumatomer. I noen få av tilfellene traff kaliumkjernen midt i kjernen til et kaliforniumatom, og det ble laget ett atom av oganesson.
        
        Oganesson-atomer er så store og tunge at de ikke klarer å holde seg samlet veldig lenge. Derfor har atomene veldig kort «holdbarhet», og går i stykker av seg selv i løpet av under et sekund. Siden det bare er laget ett atom i gangen av oganesson vet vi veldig lite om hvordan det oppfører seg, men siden det er i familien med edelgasser tror forskere at det kan ligne på de andre stoffene i denne familien.`,
        'credit': `Bilde 1: Jason Laurea_Lawrence Livermore National Laboratory`
    }
}