import Popup from './popupHandler'
import Event from './evenDefinitions'
import { from } from 'linq-to-typescript';

export default class Timeline {
    scrollContainer: HTMLElement
    sectionEvent: HTMLElement
    popup?: Popup
    event?: Event

    constructor() {
        this.scrollContainer = document.getElementById('timeline') as HTMLElement
        this.sectionEvent = document.getElementById('section-events') as HTMLElement
        this.popup = new Popup()
        this.event = new Event()

        this.event?.events.forEach(e => {
            const iconImage = document.createElement('img')
            const iconButton = document.createElement('button')
            
            // Accessibilty
            const descriptionSpan = document.createElement('span')
            let description = `epoke ${e.period}`
            let descriptionID = `description-${e.id}`
            
            iconButton.style.left = e.position.toString() + 'px'
            iconButton.setAttribute('id', `event_${e.id}`)
            iconButton.setAttribute('class', 'events-item')
            iconButton.setAttribute('aria-label', `${e.iconName} år ${e.year} åpne vindu`)
            iconButton.setAttribute('title', `${e.iconName}`)
            iconButton.setAttribute('aria-describedby', descriptionID)
            iconButton.style.bottom = e.iconYPos.toString() + 'px'
            
            iconImage.setAttribute('aria-hidden', 'true')
            iconImage.setAttribute('class', 'event-icon')
            iconImage.setAttribute('src', `assets/icons/${e.icon}.png`)
            iconImage.setAttribute('alt', `${e.iconName}`)
            
            // Accessibilty
            descriptionSpan.innerHTML = description
            descriptionSpan.setAttribute('id', descriptionID)
            descriptionSpan.setAttribute('aria-hidden', 'true')
            descriptionSpan.style.display = 'none'
            
            iconButton.appendChild(descriptionSpan)
            iconButton.appendChild(iconImage)
            this.sectionEvent.appendChild(iconButton)
            
            iconButton.addEventListener('click', () => {
                this.popup?.generateContent(e.id, e.color)
            })
        })
    }
}