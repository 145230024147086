import { Content, MediaContent, CarouselImage, Video, TextContent, getContent } from "./content";

type PauseFunction = (pause: boolean) => void;

export default class PopupHandler {
    popupContainer: HTMLElement
    popupModal: HTMLElement
    closeButton: HTMLElement
    carouselContainer: HTMLElement
    carouselInnerBlock: HTMLElement
    carouselIndicator: HTMLElement
    carouselNav: HTMLElement
    mediaButtonMusic: HTMLElement
    mediaButtonVoice: HTMLElement
    mediaButtonVideo: HTMLElement
    textTitle: HTMLElement
    textPreface: HTMLElement
    textBody: HTMLElement
    textCredit: HTMLElement
    videoIFrame: HTMLElement
    videoCaption: HTMLElement
    mediaTab: HTMLElement
    media?: MediaContent
    text?: TextContent
    content?: Content
    currentClickedTimelineEvent?: HTMLElement

    constructor() {
        this.popupContainer = document.getElementById('popup-main') as HTMLElement
        this.popupModal = document.getElementById('popup-modal') as HTMLElement
        this.closeButton = document.getElementById('close-popup') as HTMLElement
        this.carouselContainer = document.getElementById('carousel-container') as HTMLElement
        this.carouselInnerBlock = document.getElementById('carousel-container')?.getElementsByClassName('carousel-inner')[0] as HTMLElement
        this.carouselIndicator = document.getElementById('carousel-nav-indicator') as HTMLElement
        this.carouselNav = document.getElementById('carousel-nav') as HTMLElement
        this.mediaButtonMusic = document.getElementById('media-tabMusic') as HTMLElement
        this.mediaButtonVoice = document.getElementById('media-tabVoice') as HTMLElement
        this.mediaButtonVideo = document.getElementById('meida-tab-video') as HTMLElement
        this.textTitle = document.getElementById('text-title') as HTMLElement
        this.textPreface = document.getElementById('text-preface') as HTMLElement
        this.textBody = document.getElementById('text-body') as HTMLElement
        this.textCredit = document.getElementById('text-credit') as HTMLElement
        this.videoCaption = document.getElementById('caption-video') as HTMLElement
        this.videoIFrame = document.getElementById('frame-video') as HTMLElement
        this.mediaTab = document.getElementById('media-tab-container') as HTMLElement
        this.closeButton.addEventListener('click', () => {
            this.hide()
        });

        this.popupContainer.addEventListener('click', (e) => {
            if (e.target === e.currentTarget) {
                this.hide()
            }
        })
    }

    show(): void {
        this.popupContainer.style.display = 'block'
        this.popupContainer.style.visibility = 'visible'
        this.popupContainer.style.opacity = '1'
        
        // Accessiblity. Setting focus to closebutton in order to focus on popupmodule.
        setTimeout(() => {
            this.closeButton.focus(), 1
        })
    }
    
    hide(): void {
        this.popupContainer.style.display = 'none'
        this.popupContainer.style.visibility = 'hidden'
        this.popupContainer.style.opacity = '0'
        this.wipeContent()
                
        // Accessiblity. Setting focus back to last clicked timeline event.
        setTimeout(() => {
            this.currentClickedTimelineEvent?.focus(), 1
        })
    }
    
    generateContent(id: string, colorCode: string): void {
        this.currentClickedTimelineEvent = document.getElementById(`event_${id}`) as HTMLElement
        this.popupModal.style.backgroundColor = colorCode
        
        this.content = getContent(id)
        this.textTitle.innerHTML = this.content?.textContent?.title ? this.content.textContent.title : id
        this.textPreface.innerHTML = this.content?.textContent?.preface ? this.content.textContent.preface : ''
        this.textBody.innerHTML = this.content?.textContent?.bodyText ? this.content.textContent.bodyText : ''
        this.textCredit.innerHTML = this.content?.textContent?.credit ? this.content.textContent.credit : ''
        
        this.generatePhotoSection(this.content?.mediaContent?.images ? this.content.mediaContent.images : null)
        this.setVideo(this.content?.mediaContent?.video ? this.content.mediaContent.video : null)
        
        let hasMedia = this.content?.mediaContent?.video ? 'flex' : 'none'
        this.mediaTab.style.display = hasMedia
        
        this.show()
    }

    generatePhotoSection(carouselImage: CarouselImage[] | null): void {
        if (carouselImage === null) return

        for (let i = 0; i < carouselImage.length; i++) {
            const item = document.createElement('div')
            let itemClass: string

            if (i == 0) itemClass = 'carousel-item h-100 active'
            else itemClass = 'carousel-item h-100'

            let innerContent = `<a href="assets/photos/${carouselImage[i].file}" data-toggle="lightbox" class="d-flex justify-content-center align-content-center h-100" aria-hidden="true"><img class="carouselImage h-100" src="assets/photos/${carouselImage[i].file}"></a>`

            if (carouselImage[i].caption) {
                innerContent += `<div class="carouselItemCaption mx-auto w-100" style="visibility:visible"><p>${carouselImage[i].caption}</p></div>`
            }

            item.setAttribute('class', itemClass)
            item.innerHTML = innerContent
            this.carouselInnerBlock.appendChild(item);

            if (carouselImage.length > 1) {
                const indicator = document.createElement('li')
                indicator.setAttribute('data-target', '#carousel-container')
                indicator.setAttribute('data-slide-to', i.toString())

                if (i == 0) indicator.setAttribute('class', 'active')

                this.carouselIndicator.appendChild(indicator)
            }
        }

        this.carouselNav.style.display = carouselImage.length >= 2 ? 'block' : 'none'
        this.carouselContainer.style.display = 'block'
    }

    setVideo(video: Video | null): void {
        if (video === null) return

        this.videoIFrame.setAttribute('src', `${video.videoURL}?enablejsapi=1`)
        this.mediaButtonVideo?.setAttribute('data-src', video.videoURL!)
        this.videoCaption.innerHTML = video.caption ? video.caption : ''
    }

    wipeContent(): void {
        // this.mediaButtonVideo.style.display = 'none'

        this.textTitle.innerHTML = ''
        this.textPreface.innerHTML = ''
        this.textCredit.innerHTML = ''
        this.textBody.innerHTML = ''
        this.textBody.scrollTop = 0

        this.carouselContainer.style.display = 'none'
        this.carouselInnerBlock.innerHTML = ''
        this.carouselIndicator.innerHTML = ''

        this.videoIFrame.setAttribute('src', '')
        this.videoCaption.innerHTML = ''
    }
}

$('#modalVideo').on('hidden.bs.modal', function () {
    const ytIFrame = document.querySelector('#videoFrame') as HTMLIFrameElement;
    ytIFrame?.contentWindow!.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
});