type EventDefinition = {
    id: string
    icon: string
    iconYPos: number
    position: number
    color: string
    iconName?: string // Accessibility
    period?: string // Accessibility
    year?: string // Accessibility
}

// Icon height position
const yPosLow: number = 35
const yPosHi: number = 150

// Colors
const colorTechnologyOld: string = '#005F9B'
const colorThinkingObservation: string = '#9E489B'
const colorAlchemy: string = '#667834'
const colorMineralAnalyze: string = '#6453A3'
const colorTransitionFromOldToNew: string = '#F37121'
const colorElectricity: string = '#D51A3C'
const colorSpectro: string = '#F38DB7'
const colorRadioactivity: string = '#458445'
const colorSynthesis: string = '#6897D0'

// Period titles
const technologyOld: string = 'Teknologi i oldtiden ca. 7000 f.kr. - 500 e.kr.'
const thinkingObservation: string = 'Tenking og observasjon ca. 700 f.kr. - 500 e.kr.'
const alchemy: string = 'Alkymi og kymi 500 - 1706.'
const mineral: string = 'Mineralanalyser og metallurgi 1524 - 1930.'
const transition: string = 'Overgang fra gammel til ny forståelse av grunnstoffer 1660 - 1800'
const electricity: string = 'Elektrisitetens århundre 1800-tallet'
const spectro: string = 'Spektroskopi fra 1859 - 1950'
const radioactivity: string = 'Radioaktivitet fra 1896 - 1943'
const synthesis: string = 'Syntese av nye grunnstoffer 1937 - i dag'

export default class Events {
    events: EventDefinition[]

    constructor() {
        this.events = [
            {
                'id': '01',
                'icon': '01',
                'iconYPos': yPosLow,
                'position': 448,
                'color': colorTechnologyOld,
                'iconName': 'gull',
                'period': technologyOld,
                'year': '7000 f.kr.'
            },
            {
                'id': '02',
                'icon': '02',
                'iconYPos': yPosLow,
                'position': 895,
                'color': colorTechnologyOld,
                'iconName': 'bronse',
                'period': technologyOld,
                'year': '3300 f.kr.'
            },
            {
                'id': '03',
                'icon': '03',
                'iconYPos': yPosLow,
                'position': 1147,
                'iconName': 'jern',
                'color': colorTechnologyOld,
                'period': technologyOld,
                'year': '1500 f.kr.'
            },
            {
                'id': '04',
                'icon': '04',
                'iconYPos': yPosLow,
                'position': 1359,
                'color': colorThinkingObservation,
                'iconName': 'fire elementer',
                'period': thinkingObservation,
                'year': 'ca. 450 f.kr.'
            },
            {
                'id': '05',
                'icon': '05',
                'iconYPos': yPosLow,
                'position': 2550,
                'color': colorAlchemy,
                'iconName': 'alembic',
                'period': alchemy,
                'year': 'ca. 900 e.kr.'
            },
            {
                'id': '06',
                'icon': '06',
                'iconYPos': yPosLow,
                'position': 3135,
                'color': colorMineralAnalyze,
                'iconName': 'gruvevogn',
                'period': mineral,
                'year': '1524'
            },
            {
                'id': '07',
                'icon': '07',
                'iconYPos': yPosHi,
                'position': 3760,
                'color': colorAlchemy,
                'iconName': 'kolbe',
                'period': alchemy,
                'year': '1669'
            },
            {
                'id': '08',
                'icon': '08',
                'iconYPos': yPosLow,
                'position': 3912,
                'color': colorAlchemy,
                'iconName': 'koboltglass',
                'period': alchemy,
                'year': '1705 - 1706'
            },
            {
                'id': '09',
                'icon': '09',
                'iconYPos': yPosHi,
                'position': 4177,
                'color': colorMineralAnalyze,
                'iconName': 'kobolt gruvevogn',
                'period': mineral,
                'year': '1735'
            },
            {
                'id': '10',
                'icon': '10',
                'iconYPos': yPosHi,
                'position': 4796,
                'color': colorMineralAnalyze,
                'iconName': 'vekt',
                'period': mineral,
                'year': '1803'
            },
            {
                'id': '11',
                'icon': '11',
                'iconYPos': yPosLow,
                'position': 5397,
                'color': colorMineralAnalyze,
                'iconName': 'galliummetal',
                'period': mineral,
                'year': '1875'
            },
            {
                'id': '12',
                'icon': '12',
                'iconYPos': yPosLow,
                'position': 5841,
                'color': colorMineralAnalyze,
                'iconName': 'mineralklump',
                'period': mineral,
                'year': '1929'
            },
            {
                'id': '13',
                'icon': '13',
                'iconYPos': yPosLow,
                'position': 3682,
                'color': colorTransitionFromOldToNew,
                'iconName': 'fire stoffer',
                'period': transition,
                'year': '1661'
            },
            {
                'id': '14',
                'icon': '14',
                'iconYPos': yPosLow,
                'position': 4211,
                'color': colorTransitionFromOldToNew,
                'iconName': 'ild',
                'period': transition,
                'year': '1738'
            },
            {
                'id': '15',
                'icon': '15',
                'iconYPos': yPosHi,
                'position': 4483,
                'color': colorTransitionFromOldToNew,
                'iconName': 'luft',
                'period': transition,
                'year': '1772'
            },
            {
                'id': '16',
                'icon': '16',
                'iconYPos': yPosLow,
                'position': 4624,
                'color': colorTransitionFromOldToNew,
                'iconName': 'vann',
                'period': transition,
                'year': '1789'
            },
            {
                'id': '17',
                'icon': '17',
                'iconYPos': yPosHi,
                'position': 5365,
                'color': colorTransitionFromOldToNew,
                'iconName': 'periodesystemet',
                'period': transition,
                'year': '1869'
            },
            {
                'id': '18',
                'icon': '18',
                'iconYPos': yPosLow,
                'position': 4739,
                'color': colorElectricity,
                'iconName': 'batteri',
                'period': electricity,
                'year': '1800'
            },
            {
                'id': '19',
                'icon': '19',
                'iconYPos': yPosLow,
                'position': 4856,
                'color': colorElectricity,
                'iconName': 'metallbit som brenner i vann',
                'period': electricity,
                'year': '1807'
            },
            {
                'id': '20',
                'icon': '20',
                'iconYPos': yPosHi,
                'position': 5255,
                'color': colorSpectro,
                'iconName': 'lysspekter',
                'period': spectro,
                'year': '1860'
            },
            {
                'id': '21',
                'icon': '21',
                'iconYPos': yPosLow,
                'position': 5285,
                'color': colorSpectro,
                'iconName': 'sol',
                'period': spectro,
                'year': '1868'
            },
            {
                'id': '22',
                'icon': '22',
                'iconYPos': yPosHi,
                'position': 5523,
                'color': colorSpectro,
                'iconName': 'neon',
                'period': spectro,
                'year': '1894'
            },
            {
                'id': '23',
                'icon': '23',
                'iconYPos': yPosHi,
                'position': 5702,
                'color': colorSpectro,
                'iconName': 'røntgen',
                'period': spectro,
                'year': '1913'
            },
            {
                'id': '24',
                'icon': '24',
                'iconYPos': yPosLow,
                'position': 5559,
                'color': colorRadioactivity,
                'iconName': 'radioaktivitet',
                'period': radioactivity,
                'year': '1898'
            },
            {
                'id': '25',
                'icon': '25',
                'iconYPos': yPosLow,
                'position': 5702,
                'color': colorRadioactivity,
                'iconName': 'isotop',
                'period': radioactivity,
                'year': '1915'
            },
            {
                'id': '26',
                'icon': '26',
                'iconYPos': yPosLow,
                'position': 5947,
                'color': colorRadioactivity,
                'iconName': 'astat',
                'period': radioactivity,
                'year': '1940'
            },
            {
                'id': '27',
                'icon': '27',
                'iconYPos': yPosHi,
                'position': 5905,
                'color': colorSynthesis,
                'iconName': 'partikkel splittes i to',
                'period': synthesis,
                'year': '1937'
            },
            {
                'id': '28',
                'icon': '28',
                'iconYPos': yPosLow,
                'position': 6487,
                'color': colorSynthesis,
                'iconName': 'oganesson',
                'period': synthesis,
                'year': '2006'
            },
        ]
    }
}